import React from "react";

const HelpIcon = () => {
  return (
    <svg
    className="md:size-4 lg:size-5 xl:size-6 2xl:size-7"
      width="30"
      height="31"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9584 2.5416C9.54173 2.04993 10.4834 2.04993 11.0501 2.5416L12.3667 3.6666C12.6167 3.87493 13.0917 4.04994 13.4251 4.04994H14.8417C15.7251 4.04994 16.4501 4.77493 16.4501 5.65827V7.07493C16.4501 7.40827 16.6251 7.87493 16.8334 8.12493L17.9584 9.4416C18.4501 10.0249 18.4501 10.9666 17.9584 11.5333L16.8334 12.8499C16.6251 13.0999 16.4501 13.5666 16.4501 13.8999V15.3166C16.4501 16.1999 15.7251 16.9249 14.8417 16.9249H13.4251C13.0917 16.9249 12.6251 17.0999 12.3751 17.3083L11.0584 18.4333C10.4751 18.9249 9.5334 18.9249 8.96673 18.4333L7.65007 17.3083C7.40007 17.0999 6.92507 16.9249 6.60007 16.9249H5.14173C4.2584 16.9249 3.5334 16.1999 3.5334 15.3166V13.8916C3.5334 13.5666 3.36673 13.0916 3.1584 12.8499L2.0334 11.5249C1.55007 10.9499 1.55007 10.0166 2.0334 9.4416L3.1584 8.1166C3.36673 7.8666 3.5334 7.39993 3.5334 7.07493V5.6666C3.5334 4.78327 4.2584 4.05827 5.14173 4.05827H6.5834C6.91673 4.05827 7.3834 3.88327 7.6334 3.67493L8.9584 2.5416Z"
        stroke="#0C0507"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 7.27502V11.3"
        stroke="#0C0507"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99512 13.8333H10.0026"
        stroke="#0C0507"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HelpIcon;
