import React from "react";
import Button from "../common/Button";
import { IoCopyOutline } from "react-icons/io5";

import { toast } from "react-toastify";

const LinkModal = ({
    name,
    isLoading,
    generatedLink,
    onContinueClick,
    onAddAnotherUserClick,
    closeModal,
}) => {
  const onCopyClick = () => {
    navigator.clipboard
      .writeText(generatedLink)
      .then(() => {
        toast.success("Link copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const onOutsideClick = () => {
    if(closeModal) {
      closeModal();
    }
  }

  const onModalClick = (e) => {
    e.stopPropagation();
  }

  return (
    <div className="fixed inset-0 size-full flex items-center justify-center" onClick={onOutsideClick}>
      <div className="bg-white rounded-lg p-10 max-w-[90%] md:max-w-[50%] lg:max-w-[30%] flex flex-col gap-4 md:gap-8" onClick={onModalClick}>
        <div className="text-center space-y-1 md:space-y-3">
          <h1 className="font-urbanistBold md:text-[16px] xl:text-xl 2xl:text-2xl">
            Link Generated
          </h1>
          <p className="text-bodyText text-[14px] md:text-[12px] xl:text-lg font-urbanistMedium leading-tight">
            This link has been generated using your provided information. Copy
            this link and proceed to your NFC Tool app to program your patch.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h1 className="font-urbanistBold md:text-[16px] xl:text-xl 2xl:text-2xl text-center">
            {name}
          </h1>
          <div className="w-full flex flex-col gap-1">
            <p className="text-gray-700 text-[14px] md:text-[12px] xl:text-[16px] 2xl:text-lg font-manropeSemibold">
              Use Link
            </p>
            <p className="text-[14px] p-3 md:text-[12px] xl:text-[16px] md:px-2 md:py-2.5 xl:p-3 2xl:text-lg 2xl:px-4 appearance-none border border-stroke rounded-md w-full leading-tight focus:outline-none focus:shadow-outline font-manropeMedium text-gray-500 flex justify-between items-center">
              {generatedLink}{" "}
              <IoCopyOutline
                size={23}
                className="cursor-pointer"
                onClick={onCopyClick}
              />
            </p>
          </div>
          <div className="w-full flex flex-col gap-3">
            <Button
              variant={"filled"}
              onClick={onContinueClick}
              className="flex justify-center items-center"
            >
              {isLoading ? <div class="loader"></div> : "Continue"}
            </Button>
            <Button variant={"outline"} onClick={onAddAnotherUserClick}>
              Add another user
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkModal;
