import React from 'react';
import {MdDelete} from "react-icons/md";

const InputField = ({ name, label, type, placeholder, value, onChange, maxLength, required = false, className, hasDeleteButton, onDeleteClick }) => {
  return (
      <div className='w-full flex flex-col gap-1'>
          <label className="text-gray-700 text-[14px] md:text-[12px] xl:text-[16px] 2xl:text-lg font-manropeSemibold"
                 htmlFor={label}>
              {label}
          </label>
          <div className='flex items-center gap-[1em]'>
              <input
                  className={`text-[16px] p-3 md:text-[14px] xl:text-[16px] md:px-2 md:py-2.5 xl:p-3 2xl:text-lg 2xl:px-4 appearance-none border border-stroke rounded-md w-full text-black leading-tight focus:outline-none focus:shadow-outline font-manropeMedium ${className}`}
                  id={label}
                  name={name}
                  type={type}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  required={required}
                  value={value}
                  onChange={onChange}
              />
              {
                  hasDeleteButton ?
                      <div className='bg-red-200 p-2 rounded text-red-600 cursor-pointer' onClick={onDeleteClick}>
                          <div className='transition duration-500 ease-in-out hover:scale-[1.4]'>
                              <MdDelete/>
                          </div>
                      </div> : <></>
              }
          </div>
      </div>
  );
};

export default InputField;
