import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/auth`;

// Register user
const register = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/signup`, userData);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response.data.error || "An error occurred while creating account."
    );
  }
};

// Login user
const login = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/login`, userData);

    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    throw new Error(
      error.response.data.error || "An error occurred while logging you in."
    );
  }
};

// forgot passowrd
const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/forgot-password`, email);

    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error || "An error occurred");
  }
};

// reset passowrd
const resetPassword = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/resetPassword`, data);

    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error || "An error occurred");
  }
};

// reset passowrd
const addSignatures = async (token, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${API_URL}/addSignatures`, data, config);

    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error || "An error occurred");
  }
};

// Edit user profile
const editProfile = async (token, userData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${API_URL}/profile`, userData, config);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

// Logout user
const logout = () => {
  localStorage.clear();
  localStorage.removeItem("user");
};

const authService = {
  login,
  register,
  logout,
  forgotPassword,
  resetPassword,
  addSignatures,
  editProfile,
};

export default authService;
