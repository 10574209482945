import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import InputField from "../components/common/InputField";
import Button from "../components/common/Button";
import FeaturedContent from "../components/common/FeaturedContent";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { register, reset } from "../features/auth/authSlice";

const CreateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const [agreeChecked, setAgreeChecked] = useState(false);

  const handleAgreeChange = () => {
    setAgreeChecked(!agreeChecked);
  };

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (agreeChecked) {
      dispatch(register(formData));
    } else {
      toast.error("You must agree to the privacy policy to sign up.");
    }
  };

  useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(reset());
    }

    if (isSuccess) {
      toast.success("Account created successfully!");
      navigate("/login");
      dispatch(reset());
    }
  }, [dispatch, isSuccess, isError, message]);

  return (
    <div className="flex justify-between h-[100vh] min-h-[300px] md:min-h-[900px]">
      <div className="flex-1 justify-center items-center relative hidden lg:flex">
        <FeaturedContent />
      </div>

      <div className="flex-1 flex justify-center items-center">
        <div className="max-w-[84%] md:max-w-[40%] lg:max-w-[60%] flex flex-col items-center justify-center gap-3 md:gap-1 lg:gap-2 xl:gap-4 2xl:gap-8">
          <img
            src="/assets/imgs/logo.png"
            className="w-[120px] h-[60px] md:w-[70px] md:h-[35px] lg:w-[120px] lg:h-[60px] xl:w-[150px] xl:h-[75px] 2xl:w-[182px] 2xl:h-[90px] "
            alt="company-logo"
          />
          <div className="text-center space-y-2 md:space-y-1 xl:space-y-3">
            <h1 className="font-urbanistSemibold text-[20px] md:text-lg lg:text-[20px] xl:text-2xl 2xl:text-4xl">
              Create Account
            </h1>
            <p className="font-urbanistMedium text-bodyText leading-tight text-[14px] xl:text-[19px] 2xl:text-xl">
              Enter your credentials to explore all features of the website.
            </p>
          </div>

          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-3 md:gap-2 xl:gap-4 2xl:gap-6"
          >
            <InputField
              name="fullName"
              label="Full Name"
              type="text"
              placeholder={"Enter your full name"}
              value={formData.fullName}
              onChange={handleChange}
              required={true}
            />

            <InputField
              name="email"
              label="Email Address"
              type="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleChange}
              required={true}
            />

            <InputField
              name="password"
              label="Password"
              type="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required={true}
            />

            <div className="flex items-center my-2">
              <input
                type="checkbox"
                id="agreeCheckbox"
                checked={agreeChecked}
                onChange={handleAgreeChange}
                className="w-[22px] h-[22px] cursor-pointer"
              />
              <label
                htmlFor="agreeCheckbox"
                className="ml-2 font-manropeMedium text-[14px] md:text-[12px] xl:text-[16px] 2xl:text-lg"
              >
                Agree to all of the{" "}
                <Link to="/privacy-and-security" className="text-primary">Privacy Policy</Link>
              </label>
            </div>

            <Button
              type="submit"
              variant="filled"
              className="flex justify-center items-center"
              disabled={!agreeChecked || isLoading}
            >
              {isLoading ? <div className="loader"></div> : "Sign Up"}
            </Button>
          </form>

          <div className="mt-3 text-center text-[14px] font-manropeSemibold 2xl:text-lg md:text-[12px] xl:text-[16px] 2xl:gap-5">
            <p>
              Already have an Account?{" "}
              <Link to="/login" className="text-primary">
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
