import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";

const PrivacyPolicy = () => {
  return (
    <div>
      <Sidebar>
        <Header headerFor="privacy" name="Privacy Policy" />
        <div className="p-5 flex flex-col gap-6">
          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Introduction
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              Welcome to The Reunite's sign-up website ("Website"). This Privacy
              Policy outlines how we collect, use, and protect your personal
              information when you use our Website. We are committed to
              safeguarding your privacy and complying with all relevant laws and
              regulations, including the Children's Online Privacy Protection
              Act (COPPA) in the United States and other applicable data
              protection laws.
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Information We Collect
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              When you sign up on our Website, we may collect the following
              types of personal information:
              <ul className="list-disc ps-10">
                <li>
                  <span className="font-bold">
                    First and last name(optional):
                  </span>{" "}
                  We collect the full names of individuals or guardians to
                  accurately identify users and facilitate communication
                  regarding account management and our services. Please do not
                  provide the first or last names of any child under the age of
                  18. This policy helps ensure the privacy and protection of
                  minors using our services.
                </li>
                <li>
                  <span className="font-bold">Allergy details (optional):</span>{" "}
                  In the interest of safety and well-being, we request allergy
                  information, including the name of the allergy and a brief
                  description, to assist in allergy management and emergency
                  response protocols. This information helps us tailor our
                  services to accommodate specific health needs and ensure a
                  safe environment for users.
                </li>
                <li>
                  <span className="font-bold">Profile photo (optional): </span>{" "}
                  Users have the option to upload a profile photo to personalize
                  their accounts. This feature enhances user engagement and
                  facilitates recognition within our community, but it is
                  entirely voluntary. However, we ask that you do not upload
                  photos of children under the age of 18. This precaution is
                  taken to further protect the privacy and security of minors.
                </li>
                <li>
                  <span className="font-bold">Contact number (required): </span>
                  We ask for contact numbers to establish communication channels
                  and provide updates or notifications related to account
                  activity and service enhancements. These informations are
                  essential for efficient and effective communication with users
                  and guardians regarding any pertinent matters.Additionally, in
                  the event that the patch holder’s guardian needs to be
                  contacted, these phone numbers allows a third party to reach
                  the guardian.
                </li>
              </ul>
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Use of Information
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              We use the collected information for the following purposes:
              <ul className="list-disc ps-10">
                <li>
                  <span className="font-bold">
                    To create and manage user accounts:{" "}
                  </span>
                  The personal information provided during sign-up is essential
                  for establishing and maintaining user accounts on our
                  platform. This includes user authentication, account
                  customization, and access to our services.
                </li>
                <li>
                  <span className="font-bold">
                    To provide personalized services:{" "}
                  </span>
                  Understanding user preferences, such as allergy information
                  and profile details, enables us to tailor our services to meet
                  individual needs effectively. By personalizing user
                  experiences, we aim to enhance satisfaction and engagement
                  with our platform.
                </li>
                <li>
                  <span className="font-bold">
                    To communicate with users regarding their accounts and our
                    services:{" "}
                  </span>{" "}
                  We utilize the provided contact information to facilitate
                  communication with users, including account-related updates,
                  service notifications, and relevant announcements. This
                  ensures that users stay informed about their accounts and any
                  developments within our platform.
                </li>
                <li>
                  <span className="font-bold">
                    To ensure safety and security, including allergy management:{" "}
                  </span>
                  Safety and security are paramount considerations for our
                  platform, particularly concerning vulnerable individuals such
                  as children and older adults. We use collected information,
                  including allergy details, to implement appropriate safety
                  measures, respond to emergencies, and mitigate risks to user
                  health and well-being.
                </li>
                <li>
                  <span className="font-bold">
                    To improve and enhance our services:{" "}
                  </span>
                  Analysis of user data, including usage patterns and feedback,
                  enables us to identify areas for improvement and innovation
                  within our platform. By leveraging collected information, we
                  continuously strive to enhance the quality, functionality, and
                  user experience of our services.
                </li>
              </ul>
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              COPPA Compliance
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              We are committed to complying with the Children's Online Privacy
              Protection Act (COPPA) and protecting the privacy of children who
              use our website. In accordance with COPPA, we do not knowingly
              collect personal information from children under the age of 18
              without verifiable parental consent. <br /> <br /> Before
              collecting any personal information from a child under the age of
              18, we will make reasonable efforts to obtain verifiable parental
              consent through digital signature. <br /> <br /> In the event that
              we collect personal information from children under the age of 18
              with parental consent, the types of information collected may
              include but are not limited to contact information and allergies
              and any other information necessary for the provision of our
              services. <br /> <br /> Any personal information collected from
              children under the age of 18 with parental consent will only be
              used for the purpose for which it was provided, such as
              administering the NFC patch program and providing associated
              services. We will not disclose this information to third parties
              except as permitted by COPPA or with parental consent. <br />{" "}
              <br /> Parents or legal guardians have the right to review the
              personal information collected from their child, request deletion
              of this information, and refuse to permit further collection or
              use of their child's information. To exercise these rights,
              parents or legal guardians can contact us using the contact
              information provided below. The guardian also has the ability to
              delete the child’s account entirely. Additionally, once a parent
              locks the tags, the information on them cannot be updated or
              changed. <br />
              <br /> We take appropriate technical, physical, and administrative
              measures to protect the personal information of children under the
              age of 18 from unauthorized access, disclosure, alteration, or
              destruction. <br />
              <br /> In the event of any material changes to our COPPA
              practices, we will provide parents or legal guardians with notice
              and obtain verifiable parental consent as required by COPPA.{" "}
              <br />
              <br /> If you have any questions or concerns about our COPPA
              compliance or the collection of personal information from children
              under the age of 18, please contact us at{" "}
              <span className="bg-yellow-200">support@thereunite.com</span>
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Data Security
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              We implement rigorous technical and organizational measures to
              safeguard your personal information against unauthorized access,
              alteration, disclosure, or destruction. These measures include:
              <ul className="list-disc ps-10">
                <li>
                  <span className="font-bold">Data Encryption: </span>We utilize
                  industry-standard encryption protocols to secure the
                  transmission of data between your device and our servers. This
                  ensures that your personal information remains confidential
                  during transit over the internet.
                </li>
                <li>
                  <span className="font-bold">Access Control: </span>We restrict
                  access to your personal information to authorized personnel
                  only. Our access control mechanisms ensure that only
                  individuals with a legitimate need to access this data, such
                  as administrators and support staff, are granted permission to
                  do so.
                </li>
                <li>
                  <span className="font-bold">Firewall Protection </span>We
                  deploy robust firewall systems to monitor and filter incoming
                  and outgoing network traffic. This helps prevent unauthorized
                  access to our systems and prevents malicious entities from
                  compromising the integrity of your personal information.
                </li>
                <li>
                  <span className="font-bold">Regular Security Audits: </span>We
                  conduct periodic security audits and assessments to identify
                  and address potential vulnerabilities in our systems and
                  processes. This proactive approach allows us to continually
                  strengthen our defenses and protect against emerging threats.
                </li>
                <li>
                  <span className="font-bold">Employee Training: </span>We
                  provide comprehensive training to our employees on data
                  security best practices and compliance requirements. By
                  raising awareness and promoting a culture of
                  security-consciousness, we empower our staff to play an active
                  role in safeguarding your personal information.
                </li>
                <li>
                  <span className="font-bold">Data Backups: </span>We maintain
                  secure backup systems to ensure the integrity and availability
                  of your personal information in the event of unforeseen
                  incidents such as hardware failures or cyberattacks. These
                  backups are stored in encrypted formats and regularly tested
                  for reliability.
                </li>
                <li>
                  <span className="font-bold">Incident Response Plan: </span>In
                  the event of a data breach or security incident, we have
                  established procedures in place to promptly detect, assess,
                  and respond to the incident. Our incident response plan
                  includes steps for notifying affected individuals and relevant
                  authorities as required by law.Immediate Action: Upon
                  detecting a data breach, we will immediately initiate our
                  incident response plan to contain the breach and mitigate any
                  further risks.
                </li>
                <li>
                  <span className="font-bold">Assessment: </span>We will conduct
                  a thorough investigation to determine the scope and impact of
                  the breach, identifying the affected systems and data.
                </li>
                <li>
                  <span className="font-bold">Notification: </span>We will
                  promptly notify the account holder of any breach involving
                  their personal information through email. This notification
                  will include details about the breach, the types of
                  information compromised, and the steps we are taking to
                  address the situation.
                </li>
                <li>
                  <span className="font-bold">Mitigation: </span>We will take
                  appropriate steps to mitigate the damages, including securing
                  affected systems, restoring data from backups, and
                  implementing measures to prevent future breaches.
                </li>
              </ul>
              It's important to note that while we diligently implement robust
              security measures to protect your personal information, no system
              can guarantee absolute security. Despite our best efforts, the
              inherent nature of technology and the ever-evolving threat
              landscape means that no system is immune to potential risks or
              vulnerabilities. Therefore, we cannot warrant or represent that
              our security measures will be completely effective in preventing
              unauthorized access, alteration, disclosure, or destruction of
              your personal information. <br />
              <br />
              By implementing these comprehensive measures, we are committed to
              upholding the highest standards of data security and protecting
              your personal information from unauthorized access, alteration,
              disclosure, or destruction.
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Sharing of Information
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              We do not sell, trade, or otherwise transfer your personal
              information to third parties without your consent, except in the
              following circumstances:
              <ul className="list-disc ps-10">
                <li>
                  <span className="font-bold">As Required by Law: </span> We may
                  disclose your personal information if required to do so by
                  law, regulation, or legal process, such as in response to a
                  court order or subpoena.
                </li>
                <li>
                  <span className="font-bold">To Provide Our Services: </span>In
                  order to fulfill our contractual obligations and provide you
                  with the services you have requested, we may need to share
                  your personal information with trusted service providers, such
                  as payment processors, shipping companies, or IT vendors.
                  These third-party service providers are contractually
                  obligated to only use your information for the purposes of
                  providing the agreed-upon services and are bound by
                  confidentiality obligations.
                </li>
                <li>
                  <span className="font-bold">
                    In the Event of a Merger, Acquisition, or Sale:{" "}
                  </span>
                  If our company undergoes a merger, acquisition, or sale of
                  part or all of its assets, including your personal
                  information, we will notify you via email or prominent notice
                  on our Website before your personal information is transferred
                  and becomes subject to a different privacy policy.
                </li>
                <li>
                  <span className="font-bold">
                    With Our Service Providers:{" "}
                  </span>
                  We may engage third-party service providers to assist with
                  various aspects of our business operations, such as hosting
                  services, data analytics, or customer support. These service
                  providers may have access to your personal information only to
                  the extent necessary to perform their functions and are
                  contractually obligated to maintain the confidentiality and
                  security of your information.
                </li>
              </ul>
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              State-Specific Privacy Disclosure
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              This additional state-specific privacy disclosure page
              ("Disclosure") supplements the Reunite Privacy Policy. The Reunite
              Privacy Policy describes the personal information that we collect,
              the sources from which we collect it, the purposes for which we
              use it, the limited circumstances under which we share personal
              information, and with whom we share it. These additional
              disclosures are required by the California Privacy Rights Act,
              Colorado Privacy Act, Connecticut Data Privacy Act, Utah Consumer
              Privacy Act, and Virginia Consumer Data Protection Act, and serve
              as a Notice at Collection under the California Privacy Rights Act:
              <div className="mt-5 ps-10 flex flex-col gap-4 text-black">
                <div>
                  <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
                    Categories of Personal Information Collected:
                  </h1>
                  <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                    The personal information that Reunite collects, or has
                    collected from consumers in the twelve months prior to the
                    effective date of this Disclosure, fall into the following
                    categories established by the California Privacy Rights Act,
                    depending on which Reunite Service is used: <br /> <br />
                    A. Identifiers such as your name, alias, address, phone
                    numbers, IP address, your Reunite account log-in
                    information. <br />
                    B. Personal information, such as payment information. <br />
                    C. Commercial information, such as purchase and content
                    streaming activity. <br />
                    D. Internet or other electronic network activity
                    information, including content interaction information.{" "}
                    <br />
                    E. Geolocation data, such as the location of your device or
                    computer. <br />
                    F. Audio or visual information, such as voice recordings
                    when you interact with Reunite services. <br />
                    G. Education information, such as information you provide
                    through educational skills linked to your Reunite account.{" "}
                    <br />
                    H. Inference data, such as information about your purchase
                    preferences. <br />
                  </p>
                </div>

                <div>
                  <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
                    Categories of Personal Information Disclosed for a Business
                    Purpose:
                  </h1>
                  <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                    The personal information that Reunite disclosed to third
                    parties identified in the "WITH WHOM WE SHARE YOUR PERSONAL
                    DATA" section of the Reunite Privacy Policy about consumers
                    for a business purpose in the twelve months prior to the
                    effective date of this Disclosure falls into the categories
                    listed above, depending on which Reunite Service is used.
                  </p>
                </div>

                <div>
                  <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
                    Advertising:
                  </h1>
                  <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                    As explained in our Privacy Policy, Reunite shares limited
                    information to help ensure you receive more useful and
                    relevant Reunite ads and to measure their effectiveness.
                  </p>
                </div>

                <div>
                  <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
                    Your Data Rights:
                  </h1>
                  <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                    You may have certain data rights under state privacy laws,
                    including the right to request information about the
                    collection of your personal information by Reunite, to
                    access your personal information in a portable format, and
                    to correct or delete your personal information.
                  </p>
                </div>

                <div>
                  <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
                    No Sale of Personal Information:
                  </h1>
                  <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                    In the twelve months prior to the effective date of this
                    Disclosure, Reunite has not sold any personal information of
                    consumers, as those terms are defined under the California
                    Privacy Rights Act.
                  </p>
                </div>

                <div>
                  <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
                    Sensitive Personal Information Disclosure:
                  </h1>
                  <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                    Reunite does not use or disclose sensitive personal
                    information for any purpose not expressly permitted by the
                    California Privacy Rights Act.
                  </p>
                </div>

                <div>
                  <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
                    Retention Disclosure:
                  </h1>
                  <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                    We keep your personal information to enable your continued
                    use of Reunite Services, for as long as it is required in
                    order to fulfill the relevant purposes described in the
                    Reunite Privacy Policy, as permitted or as may be required
                    by law, or as otherwise communicated to you.
                  </p>
                </div>

                <div>
                  <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
                    Non-discrimination Statement:
                  </h1>
                  <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                    Reunite will not discriminate against any consumer for
                    exercising their rights under the California Privacy Rights
                    Act.
                  </p>
                </div>

                <div>
                  <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
                    De-identified Data Disclosure:
                  </h1>
                  <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                    Reunite may use de-identified data in some instances,
                    maintaining such data without attempting to re-identify it
                    or treating such data as personal data subject to applicable
                    law.
                  </p>
                </div>

                <div>
                  <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
                    Profiling Disclosure:
                  </h1>
                  <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
                    Reunite does not engage in profiling of consumers in
                    furtherance of automated decisions that produce legal or
                    similarly significant effects, as those terms are defined
                    under the Colorado Privacy Act.
                  </p>
                </div>
              </div>
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Changes to Privacy Policy
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              We reserve the right to update or modify this Privacy Policy at
              any time. Any changes will be effective immediately upon posting
              the revised Privacy Policy on this Website.
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Contact Us
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              If you have any questions or concerns regarding this Privacy
              Policy or our data practices, please contact us at:
              support@thereunite.com
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Effective Date
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              This Privacy Policy is effective as of{" "}
              <span className="bg-yellow-200">07/01/2024.</span>
            </p>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default PrivacyPolicy;
