import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";

const TermsAndConditions = () => {

  return (
    <div>
      <Sidebar>
        <Header headerFor="terms" name="Terms & Conditions" />
        <div className="p-5 flex flex-col gap-6">
          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Introduction
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              These terms and conditions govern your use of our sign-up website
              ("Website"), which is owned and operated by The Reunite
              ("Company"). <br /> <br />
              By accessing or using our Website, including but not limited to
              browsing the Website, registering an account, or submitting any
              information, you agree to be bound by these terms and conditions.
              If you disagree with any part of these terms and conditions or our
              Privacy Policy, you must not use our Website.
              <br /> <br />
              If you are accessing or using the Website on behalf of a company
              or other legal entity, you represent and warrant that you have the
              authority to bind such entity to these terms and conditions, and
              in such case, "you" and "your" will refer to such entity. If you
              do not agree to these terms and conditions, you are not authorized
              to access or use the Website, and you must cease all use
              immediately.
              <br /> <br />
              Any new features, tools, or services which are added to the
              Website shall also be subject to these terms and conditions. The
              Company reserves the right to update, change, or replace any part
              of these terms and conditions by posting updates and/or changes to
              our Website. It is your responsibility to check this page
              periodically for changes. Your continued use of or access to the
              Website following the posting of any changes constitutes
              acceptance of those changes.
              <br /> <br />
              The headings used in this agreement are included for convenience
              only and will not limit or otherwise affect these terms and
              conditions.
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Registration
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              In order to access certain features of our website, such as
              personalized services or restricted content, you may be required
              to register an account. By registering an account, you agree to
              provide accurate, current, and complete information about yourself
              as prompted by the registration form. You also agree to maintain
              and promptly update this information to ensure it remains
              accurate, current, and complete. Failure to do so constitutes a
              breach of the terms, which may result in immediate termination of
              your account on our website. <br />
              <br />
              You are responsible for maintaining the confidentiality of your
              account and password. You agree to accept responsibility for all
              activities that occur under your account, whether or not
              authorized by you. You agree to notify us immediately of any
              unauthorized access to or use of your account or any other breach
              of security. We will not be liable for any loss or damage arising
              from your failure to comply with this security obligation.
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Use of Information
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              By signing up on our website, you agree to provide information
              about yourself or the person you are registering, including but
              not limited to name, contact information, and any relevant medical
              or emergency contact details. You also consent to the collection,
              processing, and storage of this information in accordance with our
              Privacy Policy. It is your responsibility to ensure that the
              information provided is accurate, up-to-date, and complete. <br />{" "}
              <br />
              We will only use the information provided for the purpose of
              administering the NFC patch program and providing associated
              services. This includes but is not limited to facilitating
              communication between users, managing user accounts, and providing
              customer support. We will not disclose your personal information
              to third parties unless required by law or with your explicit
              consent. <br /> <br />
              We may use the information provided to contact you regarding
              updates, promotions, or other relevant information about our
              products and services. By signing up on our website, you consent
              to receive such communications via email, phone, or other contact
              methods provided. You may opt-out of receiving marketing
              communications at any time by following the unsubscribe
              instructions included in these communications or by contacting us
              directly.
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Privacy
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              Your privacy is important to us. We are committed to protecting
              the privacy and confidentiality of your personal information.
              Please refer to our Privacy Policy for detailed information on how
              we collect, use, store, and disclose your personal information.
              Our Privacy Policy outlines our practices regarding data
              collection, processing, and security measures implemented to
              safeguard your information. By using our website, you acknowledge
              that you have read and understood our Privacy Policy and consent
              to the collection and use of your personal information as
              described therein.
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Intellectual Property
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              All content on this website, including but not limited to text,
              graphics, logos, images, audio clips, video clips, digital
              downloads, data compilations, and software, is the exclusive
              property of The Reunite or its content suppliers and is protected
              by intellectual property laws, including copyright laws, trademark
              laws, and other proprietary rights. You agree not to reproduce,
              modify, distribute, display, perform, or create derivative works
              of any content on this website without the prior written consent
              of The Reunite. <br /> <br />
              The trademarks, service marks, trade names, and logos
              (collectively referred to as "Marks") displayed on this website
              are registered and unregistered Marks of The Reunite and its
              licensors. You agree not to use any Marks without the prior
              written consent of The Reunite or the respective owner of the
              Mark.
              <br /> <br />
              The Reunite grants you a limited, non-exclusive, non-transferable,
              revocable license to access and use the content on this website
              for personal, non-commercial purposes only. This license does not
              include the right to download (other than page caching) or modify
              any portion of the website, except with the express written
              consent of The Reunite. This license does not permit the resale or
              commercial use of any content on this website, the collection and
              use of any product listings, descriptions, or prices, the
              derivative use of this website or its contents, the downloading or
              copying of account information for the benefit of another
              merchant, or any use of data mining, robots, or similar data
              gathering and extraction tools.
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Limitation of Liability
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              To the fullest extent permitted by law, we shall not be liable for
              any indirect, incidental, special, consequential, or punitive
              damages, or any loss of profits or revenues, whether incurred
              directly or indirectly, or any loss of data, use, goodwill, or
              other intangible losses, resulting from your use of our website.{" "}
              <br /> <br />
              You agree to indemnify, defend, and hold harmless The Reunite and
              its affiliates, officers, directors, employees, agents, and
              licensors from and against any and all claims, liabilities,
              damages, losses, costs, expenses, or fees (including reasonable
              attorneys' fees) arising from or related to your violation of
              these terms and conditions, your use or misuse of the website, or
              any infringement by you, or any third party using your account, of
              any intellectual property or other right of any person or entity.{" "}
              <br /> <br />
              In no event shall our total liability to you for all damages,
              losses, and causes of action (whether in contract, tort, including
              negligence, or otherwise) exceed the amount paid by you, if any,
              for accessing or using our website.
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Disclaimer of Warranties
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              The website and its contents are provided on an "as is" and "as
              available" basis. We make no representations or warranties of any
              kind, express or implied, regarding the accuracy, reliability,
              completeness, suitability, or availability of the website or its
              contents for any purpose. <br /> <br />
              To the fullest extent permitted by applicable law, we disclaim all
              warranties, express or implied, including but not limited to
              implied warranties of merchantability, fitness for a particular
              purpose, and non-infringement. <br /> <br />
              We do not warrant that the website will be uninterrupted or
              error-free, that defects will be corrected, or that the website or
              the server that makes it available are free of viruses or other
              harmful components.
              <br /> <br />
              Any reliance you place on the website and its contents is strictly
              at your own risk. You acknowledge and agree that you bear sole
              responsibility for any consequences arising from your use of the
              website. <br /> <br />
              Any mention of third-party products, services, websites, or
              resources on the website is for informational purposes only and
              does not constitute an endorsement or recommendation. <br />{" "}
              <br />
              The content on the website is provided for general information
              purposes only and does not constitute professional advice. You
              should not rely on the content as a substitute for professional
              advice relevant to your specific circumstances.
              <br /> <br />
              We do not guarantee any specific results from the use of the
              website or its contents. Your use of the website and reliance on
              any information provided is solely at your own discretion and
              risk.
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Governing Law
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              These terms and conditions shall be governed by and construed in
              accordance with the laws of the state of Virginia, USA, without
              regard to its conflict of law principles. <br /> <br />
              Any dispute arising out of or relating to these terms and
              conditions, including but not limited to their validity,
              interpretation, enforcement, or breach, shall be subject to the
              exclusive jurisdiction of the state and federal courts located in
              the state of Virginia, USA. <br /> <br />
              You agree to waive any right to a jury trial in any legal
              proceeding arising out of or related to these terms and
              conditions.
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Changes to Terms and Conditions
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              We reserve the right to modify or revise these terms and
              conditions at any time without prior notice. Any changes will be
              effective immediately upon posting on the website. We may notify
              you of changes to these terms and conditions by posting a
              prominent notice on our website or by sending you an email to the
              email address associated with your account. It is your
              responsibility to review these terms and conditions periodically
              for changes. Your continued use of the website following the
              posting of any changes constitutes acceptance of those changes. If
              you do not agree to the modified terms and conditions, you must
              discontinue your use of the website immediately.
            </p>
          </div>

          <div>
            <h1 className="font-manropeBold md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-xl">
              Contact Information
            </h1>
            <p className="font-manropeMedium text-bodyText md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]">
              If you have any questions or concerns about these terms and
              conditions, please contact us at support@thereunite.com.
            </p>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default TermsAndConditions;
