import React from "react";

const InformationIcon = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8632 13.0682L23.3291 11.2727C23.045 10.9318 22.8064 10.2955 22.8064 9.84091V7.90909C22.8064 6.70455 21.8177 5.71591 20.6132 5.71591H18.6814C18.2268 5.71591 17.5791 5.47727 17.2382 5.19318L15.4427 3.65909C14.6586 2.98864 13.3746 2.98864 12.5905 3.65909L10.7723 5.19318C10.4314 5.47727 9.79501 5.71591 9.34047 5.71591H7.37456C6.17001 5.71591 5.18137 6.70455 5.18137 7.90909V9.84091C5.18137 10.2841 4.9541 10.9205 4.67001 11.2614L3.13592 13.0682C2.47683 13.8636 2.47683 15.1364 3.13592 15.9091L4.67001 17.7159C4.9541 18.0455 5.18137 18.6932 5.18137 19.1364V21.0795C5.18137 22.2841 6.17001 23.2727 7.37456 23.2727H9.35183C9.79501 23.2727 10.4427 23.5114 10.7836 23.7955L12.5791 25.3295C13.3632 26 14.6473 26 15.4314 25.3295L17.2268 23.7955C17.5677 23.5114 18.2041 23.2727 18.6586 23.2727H20.5905C21.795 23.2727 22.7836 22.2841 22.7836 21.0795V19.1477C22.7836 18.6932 23.0223 18.0568 23.3064 17.7159L24.8405 15.9205C25.5336 15.1477 25.5336 13.8636 24.8632 13.0682ZM13.1473 10.1023C13.1473 9.63636 13.5336 9.25 13.9996 9.25C14.4655 9.25 14.8518 9.63636 14.8518 10.1023V15.5909C14.8518 16.0568 14.4655 16.4432 13.9996 16.4432C13.5336 16.4432 13.1473 16.0568 13.1473 15.5909V10.1023ZM13.9996 20.0341C13.3746 20.0341 12.8632 19.5227 12.8632 18.8977C12.8632 18.2727 13.3632 17.7614 13.9996 17.7614C14.6246 17.7614 15.1359 18.2727 15.1359 18.8977C15.1359 19.5227 14.6359 20.0341 13.9996 20.0341Z"
        fill="#53C2E3"
      />
    </svg>
  );
};

export default InformationIcon;
