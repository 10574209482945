import React from "react";

const NotificationIcon = () => {
  return (
    <svg
    className="md:size-4 lg:size-5 xl:size-6 2xl:size-7"
      width="30"
      height="31"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 5.8667V8.6417"
        stroke="#2C2C2C"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M10.0171 2.16663C6.9504 2.16663 4.46707 4.64996 4.46707 7.71663V9.46663C4.46707 10.0333 4.23374 10.8833 3.94207 11.3666L2.88374 13.1333C2.23374 14.225 2.68374 15.4416 3.88374 15.8416C7.86707 17.1666 12.1754 17.1666 16.1587 15.8416C17.2837 15.4666 17.7671 14.15 17.1587 13.1333L16.1004 11.3666C15.8087 10.8833 15.5754 10.025 15.5754 9.46663V7.71663C15.5671 4.66663 13.0671 2.16663 10.0171 2.16663Z"
        stroke="#2C2C2C"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M12.7746 16.1833C12.7746 17.7083 11.5246 18.9583 9.99961 18.9583C9.24128 18.9583 8.54128 18.6417 8.04128 18.1417C7.54128 17.6417 7.22461 16.9417 7.22461 16.1833"
        stroke="#2C2C2C"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default NotificationIcon;
