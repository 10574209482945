import React from "react";

const PrivacyIcon = () => {
  return (
    <svg
    className="md:size-4 lg:size-5 xl:size-6 2xl:size-7"
      width="30"
      height="31"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4252 9.76671C17.4252 13.8417 14.4669 17.6584 10.4252 18.775C10.1502 18.85 9.85019 18.85 9.57519 18.775C5.53352 17.6584 2.5752 13.8417 2.5752 9.76671V6.10836C2.5752 5.42503 3.09187 4.65003 3.73354 4.3917L8.37519 2.49172C9.41685 2.06672 10.5919 2.06672 11.6335 2.49172L16.2752 4.3917C16.9085 4.65003 17.4335 5.42503 17.4335 6.10836L17.4252 9.76671Z"
        stroke="#2C2C2C"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99967 10.9167C10.9201 10.9167 11.6663 10.1705 11.6663 9.25004C11.6663 8.32957 10.9201 7.58337 9.99967 7.58337C9.0792 7.58337 8.33301 8.32957 8.33301 9.25004C8.33301 10.1705 9.0792 10.9167 9.99967 10.9167Z"
        stroke="#2C2C2C"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 10.9167V13.4167"
        stroke="#2C2C2C"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PrivacyIcon;
