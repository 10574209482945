import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import enrolleeService from "./enrolleeService";

// Get enrollees from localStorage
var enrollees = JSON.parse(localStorage.getItem("enrollees"));

const initialState = {
  enrollees: enrollees ? enrollees : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Add signatures
export const addEnrollee = createAsyncThunk(
  "enrollee/addEnrollee",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await enrolleeService.addEnrollee(token, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get enrollees by userId
export const getEnrolleesByUserId = createAsyncThunk(
  "enrollee/getEnrolleesByUserId",
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await enrolleeService.getEnrolleesByUserId(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete enrollees by Id
export const deleteEnrollee = createAsyncThunk(
  "enrollee/deleteEnrollee",
  async (enrolleeId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await enrolleeService.deleteEnrollee(token, enrolleeId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const enrolleeSlice = createSlice({
  name: "enrollees",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addEnrollee.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(addEnrollee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.enrollees = action.payload;
        state.message = "";
      })
      .addCase(addEnrollee.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.enrollees = null;
      })
      .addCase(getEnrolleesByUserId.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(getEnrolleesByUserId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.enrollees = action.payload;
        state.message = "";
      })
      .addCase(getEnrolleesByUserId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.enrollees = null;
      })
      .addCase(deleteEnrollee.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(deleteEnrollee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.enrollees = action.payload;
        state.message = "Enrollee Deleted Successfully";
      })
      .addCase(deleteEnrollee.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.enrollees = null;
      });
  },
});

export const { reset } = enrolleeSlice.actions;
export default enrolleeSlice.reducer;
